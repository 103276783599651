<template>
    <div class="container">
        <div class="page-container">
            <el-table ref="table" :data="sendArr" border stripe :row-key="'storeId'">
                <el-table-column prop="storeId" label="序号" align="center" width="60" fixed="left">
                    <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column label="门店logo" align="center">
                    <template slot-scope="scope">
                        <el-image class="table-td-thumb" :src="scope.row.storeLogo"
                            :preview-src-list="[scope.row.storeLogo]" fit="cover">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="storeName" label="门店名称" align="center">
                </el-table-column>
                <el-table-column prop="storeAddress" label="门店地址" align="center">
                </el-table-column>
            </el-table>
        </div>
        <!-- 查看门店详情 -->
        <show-content v-model="dialogSeeContent" :title="rowInfo.title" :content="rowInfo.content"></show-content>


    </div>
</template>

<script>
import { getList } from '@/tools/getList.js'
import ShowContent from "@/components/common/subgroup/ShowContent.vue"

export default {
    props: ['sendSelectList'],
    mixins: [getList],
    components: {
        ShowContent,
    },
    data() {

        return {
            // requst: {
            //     module: storeManager,
            //     url: 'storeList',
            //     addUrl: 'saveStore',
            //     updateUrl: 'updateStore',
            // },
            dialogSeeContent: false,
            rowInfo: {},
            query: {
                storeName: '',    //搜索词
            },

            //
            sendArr: [],

        };
    },
    created() {
        if (this.sendSelectList) {
            this.sendArr = JSON.parse(JSON.stringify(this.sendSelectList));
        }
      
    },
    methods: {
        
    },
};
</script>

<style scoped>
.mapContainer {
    width: 100%;
    height: 380px;
}

#mapContainer {
    width: 100%;
    height: 380px;
}
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-table",
            {
              ref: "table",
              attrs: {
                data: _vm.sendArr,
                border: "",
                stripe: "",
                "row-key": "storeId",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "storeId",
                  label: "序号",
                  align: "center",
                  width: "60",
                  fixed: "left",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.$index + 1))]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "门店logo", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-image",
                          {
                            staticClass: "table-td-thumb",
                            attrs: {
                              src: scope.row.storeLogo,
                              "preview-src-list": [scope.row.storeLogo],
                              fit: "cover",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error",
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-picture-outline",
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "storeName",
                  label: "门店名称",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "storeAddress",
                  label: "门店地址",
                  align: "center",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("show-content", {
        attrs: { title: _vm.rowInfo.title, content: _vm.rowInfo.content },
        model: {
          value: _vm.dialogSeeContent,
          callback: function ($$v) {
            _vm.dialogSeeContent = $$v
          },
          expression: "dialogSeeContent",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="container">
        <div class="page-container">
            <el-form :inline="true" class="fr-s-s">
                <el-form-item>
                    <el-input type="text" placeholder="请输入门店名称" v-model="query.storeName"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="refreshDataNew">搜索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain v-if="permission.couponSelectStoreList"
                        @click="couponSelectStoreHandle">批量选择</el-button>
                </el-form-item>
            </el-form>
            <el-table ref="table" :data="tableData" border stripe :row-key="'storeId'" v-loading="showLoading"
                :height="tableHeight" @select="handleCheckBox" @select-all="handleSelectAll"
                @selection-change="handleSelectionChange">
                <el-table-column v-if="permission.couponSelectStoreList" type="selection" align="center" width="60"
                    fixed="left">
                </el-table-column>
                <el-table-column prop="storeId" label="编号" align="center" width="60">
                    <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column label="门店logo" width="90" align="center">
                    <template slot-scope="scope">
                        <el-image class="table-td-thumb" :src="scope.row.storeLogo"
                            :preview-src-list="[scope.row.storeLogo]" fit="cover">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="storeName" label="门店名称" min-width="160" align="center"></el-table-column>
                <el-table-column label="所在区域" min-width="160" align="center">
                    <template slot-scope="scope">{{ scope.row.provinceName + '-' + scope.row.cityName + '-' +
                        scope.row.districtName }}</template>
                </el-table-column>
                <el-table-column prop="storeAddress" label="门店地址" min-width="230" align="center"></el-table-column>
                <el-table-column prop="unique" label="手机号" width="120" align="center">
                    <template slot-scope="scope">{{ scope.row.unique || '--' }}</template>
                </el-table-column>
                <el-table-column prop="channelId" label="机构" width="90" align="center">
                    <template slot-scope="scope">{{ scope.row.channelId || '--' }}</template>
                </el-table-column>
                <el-table-column prop="storeNum" label="门店号" width="90" align="center">
                    <template slot-scope="scope">{{ scope.row.storeNum || '--' }}</template>
                </el-table-column>
                <el-table-column prop="appId" label="应用号" width="90" align="center">
                    <template slot-scope="scope">{{ scope.row.appId || '--' }}</template>
                </el-table-column>
                <el-table-column prop="appSecret" label="授权密钥" width="120" align="center">
                    <template slot-scope="scope">{{ scope.row.appSecret || '--' }}</template>
                </el-table-column>
                <el-table-column prop="isShow" label="是否显示" width="90" align="center">
                    <template slot-scope="scope">
                        <i :class="[scope.row.isShow == 1 ? 'el-icon-check' : 'el-icon-close']"></i>
                    </template>
                </el-table-column>
                <el-table-column prop="agentName" label="代理商名称" min-width="120" align="center"
                    v-if="$store.getters.roleName == '超级用户角色' || $store.getters.roleName == '管理员'">
                    <template slot-scope="scope">{{ scope.row.agentName || '--' }}</template>
                </el-table-column>
                <el-table-column prop="orderWeight" label="权重值" width="90" align="center"></el-table-column>
                <el-table-column prop="addTime" label="添加时间" width="140" align="center">
                    <template slot-scope="scope">{{ scope.row.addTime | formatDate }}</template>
                </el-table-column>
            </el-table>
            <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit"
                layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
            </el-pagination>
        </div>
        <!-- 查看门店详情 -->
        <show-content v-model="dialogSeeContent" :title="rowInfo.title" :content="rowInfo.content"></show-content>


    </div>
</template>

<script>
import { getList } from '@/tools/getList.js'
import storeManager from "@/request/storeManager.js";
import ShowContent from "@/components/common/subgroup/ShowContent.vue"

export default {
    props: ['sendSelectList'],
    mixins: [getList],
    components: {
        ShowContent,
    },
    data() {

        return {
            // requst: {
            //     module: storeManager,
            //     url: 'storeList',
            //     addUrl: 'saveStore',
            //     updateUrl: 'updateStore',
            // },
            dialogSeeContent: false,
            rowInfo: {},
            query: {
                storeName: '',    //搜索词
            },

            //
            multipleSelection: [],
            sendArr: [],

        };
    },
    created() {
        if (this.sendSelectList) {
            this.sendArr = JSON.parse(JSON.stringify(this.sendSelectList));
        }
        this.handlePermission();
        this.getStoreList();
    },
    methods: {
        //获取列表
        async getStoreList() {
            try {
                this.showLoading = true
                let data = await storeManager.storeList(this.query)
                if (data.code == 200) {

                    this.tableData = data.data
                    this.pageTotal = data.total
                    setTimeout(() => {
                        this.tableData.forEach(row => {
                            const matchedIndex = this.sendArr.findIndex(item => item.storeId == row.storeId)
                            this.$refs['table'].toggleRowSelection(row, matchedIndex != -1)
                        })
                    }, 100)
                }
            } catch (error) {
                //
            } finally {
                this.showLoading = false
            }
        },
        refreshDataNew() {
            this.query.offset = 1
            this.getStoreList();
        },
        //更改每页显示条数
        async handleSizeChange(val) {
            this.query.limit = val;
            this.query.offset = 1;
            this.getStoreList();
        },
        //设置当前页
        async handleCurrentChange(val) {
            this.query.offset = val;
            this.getStoreList();
        },

        hoverContent(row) {
            this.rowInfo = row;
            this.dialogSeeContent = true;
        },
        // 
        handleCheckBox(val) {// 单选按钮
            // console.log('handleCheckBox=', val);
            if (this.multipleSelection.length - val.length == 1 && this.tableData.length) {//手动减少
                let differentArr = this.getDifferentArr(this.multipleSelection, val)
                this.multipleSelection = val;
                if (differentArr) {
                    this.sendArr = this.sendArr.filter(item => item.storeId != differentArr[0].storeId)
                }
            } else if (this.multipleSelection.length - val.length == -1 && this.tableData.length) {//增加
                let differentArr0 = this.getDifferentArr(val, this.multipleSelection)
                this.multipleSelection = val;
                if (differentArr0) {
                    let isHave = this.sendArr.some(function (item) {
                        return item.storeId == differentArr0[0].storeId;
                    });
                    if (!isHave) {
                        this.sendArr.push(differentArr0[0]);
                    }
                }
            } else {
                this.multipleSelection = val;
            }
            // console.log('singleS', this.sendArr);
        },
        handleSelectAll(val) {// 全选按钮
            // console.log('handleSelectAll=', val);
            this.multipleSelection = val;
            if (this.tableData.length) {
                var sendArrIds = this.sendArr.map(item => item.storeId);
                if (val.length) {//全部选择
                    val.forEach(element => {
                        if (!sendArrIds.includes(element.storeId)) {
                            this.sendArr.push(element)
                        }
                    });
                } else {//全部取消
                    this.tableData.forEach(element => {
                        if (sendArrIds.includes(element.storeId)) {
                            let index = this.sendArr.findIndex(item => item.storeId == element.storeId)
                            if (index != -1) {
                                this.sendArr.splice(index, 1);
                            }
                        }
                    });
                }
            }
            // console.log('allS', this.sendArr);
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        getDifferentArr(newArr0, newArr) {
            const result = [];
            for (let i = 0; i < newArr0.length; i++) {
                const current = newArr0[i];
                const equalObj = newArr.find(item => item.storeId === current.storeId);
                if (!equalObj) {
                    result.push(current);
                }
            }
            return result;
        },
        couponSelectStoreHandle() {
            if (this.sendArr.length == 0) {
                this.$message.error('请选择门店')
            } else {
                this.$emit('changeSelectShopArr', this.sendArr)
            }
        },

    },
};
</script>

<style scoped>
.mapContainer {
    width: 100%;
    height: 380px;
}

#mapContainer {
    width: 100%;
    height: 380px;
}
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-form",
            { staticClass: "fr-s-s", attrs: { inline: true } },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "请输入门店名称" },
                    model: {
                      value: _vm.query.storeName,
                      callback: function ($$v) {
                        _vm.$set(_vm.query, "storeName", $$v)
                      },
                      expression: "query.storeName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "" },
                      on: { click: _vm.refreshDataNew },
                    },
                    [_vm._v("搜索")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _vm.permission.couponSelectStoreList
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "" },
                          on: { click: _vm.couponSelectStoreHandle },
                        },
                        [_vm._v("批量选择")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.showLoading,
                  expression: "showLoading",
                },
              ],
              ref: "table",
              attrs: {
                data: _vm.tableData,
                border: "",
                stripe: "",
                "row-key": "storeId",
                height: _vm.tableHeight,
              },
              on: {
                select: _vm.handleCheckBox,
                "select-all": _vm.handleSelectAll,
                "selection-change": _vm.handleSelectionChange,
              },
            },
            [
              _vm.permission.couponSelectStoreList
                ? _c("el-table-column", {
                    attrs: {
                      type: "selection",
                      align: "center",
                      width: "60",
                      fixed: "left",
                    },
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: {
                  prop: "storeId",
                  label: "编号",
                  align: "center",
                  width: "60",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.$index + 1))]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "门店logo", width: "90", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-image",
                          {
                            staticClass: "table-td-thumb",
                            attrs: {
                              src: scope.row.storeLogo,
                              "preview-src-list": [scope.row.storeLogo],
                              fit: "cover",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error",
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-picture-outline",
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "storeName",
                  label: "门店名称",
                  "min-width": "160",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "所在区域",
                  "min-width": "160",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          _vm._s(
                            scope.row.provinceName +
                              "-" +
                              scope.row.cityName +
                              "-" +
                              scope.row.districtName
                          )
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "storeAddress",
                  label: "门店地址",
                  "min-width": "230",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "unique",
                  label: "手机号",
                  width: "120",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(_vm._s(scope.row.unique || "--"))]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "channelId",
                  label: "机构",
                  width: "90",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(_vm._s(scope.row.channelId || "--"))]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "storeNum",
                  label: "门店号",
                  width: "90",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(_vm._s(scope.row.storeNum || "--"))]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "appId",
                  label: "应用号",
                  width: "90",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(_vm._s(scope.row.appId || "--"))]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "appSecret",
                  label: "授权密钥",
                  width: "120",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(_vm._s(scope.row.appSecret || "--"))]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "isShow",
                  label: "是否显示",
                  width: "90",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("i", {
                          class: [
                            scope.row.isShow == 1
                              ? "el-icon-check"
                              : "el-icon-close",
                          ],
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm.$store.getters.roleName == "超级用户角色" ||
              _vm.$store.getters.roleName == "管理员"
                ? _c("el-table-column", {
                    attrs: {
                      prop: "agentName",
                      label: "代理商名称",
                      "min-width": "120",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [_vm._v(_vm._s(scope.row.agentName || "--"))]
                          },
                        },
                      ],
                      null,
                      false,
                      3454155363
                    ),
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: {
                  prop: "orderWeight",
                  label: "权重值",
                  width: "90",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "addTime",
                  label: "添加时间",
                  width: "140",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(_vm._s(_vm._f("formatDate")(scope.row.addTime))),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-pagination", {
            ref: "pagination",
            attrs: {
              "current-page": _vm.query.offset,
              "page-sizes": [10, 30, 50, 100],
              "page-size": _vm.query.limit,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pageTotal,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("show-content", {
        attrs: { title: _vm.rowInfo.title, content: _vm.rowInfo.content },
        model: {
          value: _vm.dialogSeeContent,
          callback: function ($$v) {
            _vm.dialogSeeContent = $$v
          },
          expression: "dialogSeeContent",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import BaseModule from './utils/BaseModule'
class BaseManager extends BaseModule {
	constructor() {
		super()
	}

	//体验卷列表
	couponList(param) {
		return this.get('/web/experienceCoupon/list', param)
	}
	//删除体验券
	removeCoupon(param) {
		return this.post('/web/experienceCoupon/remove', param)
	}
    //体验卷使用记录
	getExperienceApplyRecord(param) {
		return this.get('/web/experienceCoupon/getExperienceApplyRecord', param)
	}

	
}

export default new BaseManager()



import request from '@/request/utils/newrequest.js'
import { baseconfig } from "@/config.js";

//体验券
export const couponModule = {
    // 添加体验券
    async saveCoupon(param) {
        return await request({
            url: baseconfig.baseUrl + "/web/experienceCoupon/save",
            method: 'post',
            data: param
        })
    },
    // 修改体验券
	async updateCoupon(param) {
        return await request({
            url: baseconfig.baseUrl + "/web/experienceCoupon/update",
            method: 'post',
            data: param
        })
    },

}
<template>
    <div class="container">
        <div class="page-container">
            <el-form :inline="true" class="fr-s-s warp">
                <el-form-item>
                    <el-input type="text" placeholder="请输入体验券ID" v-model="query.experienceCouponId"
                        clearable></el-input>
                </el-form-item>
                <!-- <el-form-item>
                    <el-date-picker size="small" value-format="yyyy-MM-dd HH:mm:ss" style="width: 200px"
                        v-model="query.validStartTime" type="datetime" placeholder="请选择有效期开始时间"
                        :picker-options="pickerOptionsStart">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-date-picker size="small" value-format="yyyy-MM-dd HH:mm:ss" style="width: 200px"
                        v-model="query.validEndTime" type="datetime" placeholder="请选择有效期结束时间"
                        :picker-options="pickerOptionsEnd">
                    </el-date-picker>
                </el-form-item> -->
                <el-form-item>
                    <el-button type="primary" plain @click="refreshData">搜索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain v-if="permission.saveCoupon" @click="onAdd">添加</el-button>
                </el-form-item>
            </el-form>
            <el-table ref="table" :data="tableData" border stripe :row-key="'experienceCouponId'"
                v-loading="showLoading" :height="tableHeight">
                <el-table-column prop="experienceCouponId" label="编号" align="center" width="60">
                    <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column prop="experienceCouponId" label="体验券ID" align="center"
                    min-width="100"></el-table-column>
                <el-table-column prop="couponName" label="体验券名称" align="center" min-width="140"></el-table-column>
                <el-table-column prop="validStartTime" label="有效期开始时间" align="center" min-width="140">
                    <template slot-scope="scope">
                        {{ scope.row.validStartTime | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column prop="validEndTime" label="有效期结束时间" align="center" min-width="140">
                    <template slot-scope="scope">
                        {{ scope.row.validEndTime | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column prop="originalPrice" label="体验券金额" align="center" min-width="100">
                    <template slot-scope="scope">
                        <span v-if="scope.row.originalPrice">{{
                            parseFloat(scope.row.originalPrice).toFixed(2) + '元' }}</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column prop="applicableDescription" label="使用说明" min-width="100" align="center">
                    <template slot-scope="scope">
                        <span @click="rowContentHandle(scope.row, 1)">
                            <el-link type="primary">详情</el-link>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="nickName" label="用户昵称" align="center" min-width="100"></el-table-column>
                <el-table-column prop="verificationTime" label="核销时间" align="center" min-width="140">
                    <template slot-scope="scope">
                        {{ scope.row.verificationTime | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column prop="verifierName" label="核销人名称" align="center" min-width="100"></el-table-column>
                <el-table-column prop="verificationStoreName" label="核销门店" align="center"
                    min-width="120"></el-table-column>
            </el-table>
            <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit"
                layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
            </el-pagination>
        </div>
        <!-- 查看活动详情 -->
        <show-content v-model="dialogSeeContent" :title="rowInfo.title" :content="rowInfo.content"></show-content>




    </div>
</template>

<script>
import {
    getList
} from '@/tools/getList.js'
import couponManager from "@/request/couponManager.js";
import ShowContent from "@/components/common/subgroup/ShowContent.vue"

export default {
    mixins: [getList],
    components: {
        ShowContent,
    },
    data() {
        return {
            pickerOptionsStart: {
                disabledDate: (time) => {
                    if (this.query.validEndTime) {
                        return time.getTime() > new Date(this.query.validEndTime).getTime()
                    }
                },
            },
            pickerOptionsEnd: {
                disabledDate: (time) => {
                    if (this.query.validStartTime) {
                        return time.getTime() < new Date(this.query.validStartTime).getTime()
                    }
                },
            },


            requst: {
                module: couponManager,
                url: 'getExperienceApplyRecord',
                auditUrl: '',
            },
            dialogSeeContent: false,
            rowInfo: {
                title: "",
                content: "",
            },
            query: {
                experienceCouponId: '',    //搜索词
            },
            //
            dialogShopListVisible: false,

        };
    },
    created() {
    },
    filters: {

    },
    methods: {
        hoverContent(row) {
            this.rowInfo = row;
            this.dialogSeeContent = true;
        },
        //详情
        rowContentHandle(row, type) {
            switch (type) {
                case 1:
                    this.rowInfo.title = '使用说明'
                    this.rowInfo.content = row.applicableDescription
                    this.dialogSeeContent = true
                    break;
                case 2:
                    break;
                default:
                    break;
            }
        },



    },
};
</script>

<style scoped>
.session-bg {
    display: flex;
    flex-wrap: wrap;
}

.session-label {
    margin: 3px 5px 3px 0;
    background-color: #ff8143;
    color: white;
    padding: 0 5px;
    border-radius: 5px;
    line-height: 25px;
}

.card-bg {
    padding: 15px;
    display: flex;
    flex-flow: wrap;
}

.card-bg-noData {
    padding: 30px;
    text-align: center;
}

.goods-card {
    border-radius: 4px;
    border: 1px solid #EBEEF5;
    background-color: #FFFFFF;
    overflow: hidden;
    color: #303133;
    transition: 0.3s;
    /* box-sizing: border-box; */


    width: 275px;
    /* height: 160px; */
    margin: 15px 0px 15px 15px;
    /* background: red; */
    display: flex;
    padding: 15px;
    align-items: center;
}


.goods-image {
    width: 90px;
    height: 120px;
    overflow: hidden;
    display: flex;
    align-items: center;
    /* margin: 20px 0px 20px 15px; */
}

.goods-detail {
    /* padding: 20px 15px 20px 15px; */
    margin-left: 15px;
}

.goods-name {
    margin-bottom: 5px;
}

.goods-other {
    margin-bottom: 5px;
}


.text-line-1 {
    display: -webkit-box;
    word-break: break-all;
    /*超出文字用省略号代替*/
    text-overflow: ellipsis;
    /*竖直方向的超出和隐藏*/
    -webkit-box-orient: vertical;
    /*设定行数为2*/
    -webkit-line-clamp: 1;
    /*多出部分隐藏*/
    overflow: hidden;
}

.text-line-2 {
    display: -webkit-box;
    word-break: break-all;
    /*超出文字用省略号代替*/
    text-overflow: ellipsis;
    /*竖直方向的超出和隐藏*/
    -webkit-box-orient: vertical;
    /*设定行数为2*/
    -webkit-line-clamp: 2;
    /*多出部分隐藏*/
    overflow: hidden;
}
</style>

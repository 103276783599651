<template>
    <div class="container">
        <div class="page-container">
            <el-form :inline="true" class="fr-s-s warp">
                <el-form-item>
                    <el-input type="text" placeholder="请输入体验券名称" v-model="query.couponName" clearable></el-input>
                </el-form-item>
                <!-- <el-form-item>
                    <el-date-picker size="small" value-format="yyyy-MM-dd HH:mm:ss" style="width: 200px"
                        v-model="query.validStartTime" type="datetime" placeholder="请选择有效期开始时间"
                        :picker-options="pickerOptionsStart">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-date-picker size="small" value-format="yyyy-MM-dd HH:mm:ss" style="width: 200px"
                        v-model="query.validEndTime" type="datetime" placeholder="请选择有效期结束时间"
                        :picker-options="pickerOptionsEnd">
                    </el-date-picker>
                </el-form-item> -->
                <el-form-item>
                    <el-button type="primary" plain @click="refreshData">搜索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain v-if="permission.saveCoupon" @click="onAdd">添加</el-button>
                </el-form-item>
            </el-form>
            <el-table ref="table" :data="tableData" border stripe :row-key="'experienceCouponId'"
                v-loading="showLoading" :height="tableHeight">
                <el-table-column prop="experienceCouponId" label="编号" align="center" width="60">
                    <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column prop="experienceCouponId" label="体验券ID" align="center"
                    min-width="100"></el-table-column>
                <el-table-column prop="couponName" label="体验券名称" align="center" min-width="140"></el-table-column>
                <el-table-column prop="validStartTime" label="有效期开始时间" align="center" min-width="140">
                    <template slot-scope="scope">
                        {{ scope.row.validStartTime | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column prop="validEndTime" label="有效期结束时间" align="center" min-width="140">
                    <template slot-scope="scope">
                        {{ scope.row.validEndTime | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column prop="originalPrice" label="体验券金额" align="center" min-width="100">
                    <template slot-scope="scope">
                        <span v-if="scope.row.originalPrice">{{
                            parseFloat(scope.row.originalPrice).toFixed(2) + '元' }}</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column prop="applicableStoreIds" label="适用门店" min-width="100" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.applicableStoreIds.length" @click="rowContentHandle(scope.row, 2)">
                            <el-link type="primary">查看</el-link>
                        </span>
                        <span v-else>全部门店</span>
                    </template>
                </el-table-column>

                <el-table-column prop="applicableDescription" label="使用说明" min-width="100" align="center">
                    <template slot-scope="scope">
                        <span @click="rowContentHandle(scope.row, 1)">
                            <el-link type="primary">详情</el-link>
                        </span>
                    </template>
                </el-table-column>


                <el-table-column label="操作" width="140px" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" content="编辑" placement="top"
                            v-if="permission.updateCoupon">
                            <el-button class="success" type="text" icon="el-icon-edit-outline"
                                @click="clickEditInfo(scope.row)"></el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="删除" placement="top"
                            v-if="permission.removeCoupon">
                            <el-popconfirm title="您确定要删除么？" icon-color="red"
                                @confirm="deleteAdmin(scope.row, scope.$index)">
                                <el-button slot="reference" icon="el-icon-delete" type="text" class="danger">
                                </el-button>
                            </el-popconfirm>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit"
                layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
            </el-pagination>
        </div>
        <!-- 查看活动详情 -->
        <show-content v-model="dialogSeeContent" :title="rowInfo.title" :content="rowInfo.content"></show-content>
        <!-- 添加体验券 -->
        <el-dialog :title="`${editForm.experienceCouponId ? '编辑' : '添加'}体验券`" :visible.sync="addOrEditDialog"
            :modal="false" :destroy-on-close="true" fullscreen @close="resetAddOrEditData">
            <el-form label-width="180px" ref="editForm" :model="editForm" :rules="addRules">
                <el-form-item label="体验券名称" prop="couponName">
                    <el-input size="medium" v-model="editForm.couponName" placeholder="请输入体验券名称"></el-input>
                </el-form-item>
                <el-form-item label="体验券金额" prop="originalPrice">
                    <el-input type="number" v-model="editForm.originalPrice" placeholder="请填写体验券金额"></el-input>
                </el-form-item>
                <el-form-item label="有效期开始时间" prop="validStartTime" class="is-required">
                    <el-date-picker v-model="editForm.validStartTime" type="datetime" placeholder="选择有效期开始时间"
                        value-format="timestamp" :picker-options="editPickerOptionsStart">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="有效期结束时间" prop="validEndTime" class="is-required">
                    <el-date-picker v-model="editForm.validEndTime" type="datetime" placeholder="选择有效期结束时间"
                        value-format="timestamp" :picker-options="editPickerOptionsEnd">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="使用说明" prop="applicableDescription">
                    <el-input type="textarea" v-model="editForm.applicableDescription" placeholder="请填写使用说明"></el-input>
                </el-form-item>
                <el-form-item label="是否适用全部门店" prop="selectShopType">
                    <el-checkbox v-model="editForm.selectShopType" :false-label="2" :true-label="1"
                        @change="selectShopTypeChange"></el-checkbox>
                    <el-button type="primary" size="medium" style="margin-left: 15px" @click="onchoiceShops"
                        v-if="editForm.selectShopType == 2">选择门店</el-button>
                </el-form-item>
                <el-form-item label="已选门店" v-if="editForm.couponShopInfoDOList.length">
                    <el-table ref="table" :data="editForm.couponShopInfoDOList" border stripe :row-key="'storeId'">
                        <el-table-column prop="storeId" label="序号" align="center" width="60" fixed="left">
                            <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
                        </el-table-column>
                        <el-table-column label="门店logo" align="center">
                            <template slot-scope="scope">
                                <el-image class="table-td-thumb" :src="scope.row.storeLogo"
                                    :preview-src-list="[scope.row.storeLogo]" fit="cover">
                                    <div slot="error" class="image-slot">
                                        <i class="el-icon-picture-outline"></i>
                                    </div>
                                </el-image>
                            </template>
                        </el-table-column>
                        <el-table-column prop="storeName" label="门店名称" align="center">
                        </el-table-column>
                        <el-table-column prop="storeAddress" label="门店地址" align="center">
                        </el-table-column>
                    </el-table>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="clickAddOrEditCancel">取 消</el-button>
                <el-button type="primary" :loading="addOrEditBtnLoading" @click="clickAddOrEditConfirm">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 选择门店 -->
        <el-dialog :title="`选择门店`" :visible.sync="dialogShopListVisible" :modal="false" :destroy-on-close="true"
            fullscreen class="absolute">
            <CouponSelectStoreList v-if="dialogShopListVisible" :sendSelectList="editForm.couponShopInfoDOList"
                @changeSelectShopArr="handleChangeSelectShopArr" />
        </el-dialog>
        <!-- 查看适用门店 -->
        <el-dialog :title="`适用门店`" :visible.sync="dialogSelectedStoreListVisible" :modal="false" :destroy-on-close="true"
            fullscreen class="absolute">
            <SelectedStoreList v-if="dialogSelectedStoreListVisible" :sendSelectList="currentStoreList" />
        </el-dialog>


    </div>
</template>

<script>
import {
    getList
} from '@/tools/getList.js'
import couponManager from "@/request/couponManager.js";
import { couponModule } from "@/request/couponManager.js";
import ShowContent from "@/components/common/subgroup/ShowContent.vue"
import CouponSelectStoreList from '@/components/page/coupon/sub/CouponSelectStoreList.vue'
import SelectedStoreList from '@/components/page/coupon/sub/SelectedStoreList.vue'

export default {
    mixins: [getList],
    components: {
        ShowContent,
        CouponSelectStoreList,
        SelectedStoreList,
    },
    data() {
        return {
            pickerOptionsStart: {
                disabledDate: (time) => {
                    if (this.query.validEndTime) {
                        return time.getTime() > new Date(this.query.validEndTime).getTime()
                    }
                },
            },
            pickerOptionsEnd: {
                disabledDate: (time) => {
                    if (this.query.validStartTime) {
                        return time.getTime() < new Date(this.query.validStartTime).getTime()
                    }
                },
            },

            editPickerOptionsStart: {
                disabledDate: (time) => {
                    if (this.editForm.validEndTime) {
                        return time.getTime() > new Date(this.editForm.validEndTime).getTime()
                    }
                },
            },
            editPickerOptionsEnd: {
                disabledDate: (time) => {
                    if (this.editForm.validStartTime) {
                        return time.getTime() < new Date(this.editForm.validStartTime).getTime()
                    }
                },
            },

            requst: {
                module: couponManager,
                url: 'couponList',
                auditUrl: '',
            },
            dialogSeeContent: false,
            rowInfo: {
                title: "",
                content: "",
            },
            query: {
                couponName: '',    //搜索词
                validStartTime: '',
                validEndTime: '',
            },
            //
            dialogShopListVisible: false,
            //
            addOrEditDialog: false,
            addOrEditBtnLoading: false,
            editForm: {
                experienceCouponId: 0,
                couponName: "",
                validStartTime: "",
                validEndTime: "",
                originalPrice: "",
                applicableStoreIds: "",
                applicableDescription: "",
                selectShopType: 1,
                couponShopInfoDOList: [],
            },
            addRules: {
                couponName: [
                    { required: true, message: "请输入体验券标题", trigger: "blur" },
                ],
                originalPrice: [
                    { required: true, message: "请输入体验券金额(元)", trigger: "blur" },
                    {
                        // ^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$
                        pattern: /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/,
                        message: "体验券金额必须大于0，且小数点后不能超过2位",
                        trigger: "change",
                    },
                ],
                validStartTime: [{ required: true, message: "请选择有效期开始时间", trigger: "change" }],
                validEndTime: [{ required: true, message: "请选择有效期结束时间", trigger: "change" }],
                // applicableStoreIds: [{ required: true, message: "请选择有效期结束时间", trigger: "change" }],
                applicableDescription: [
                    { required: true, message: "请输入使用说明", trigger: "blur" },
                ],
                orderWeight: [
                    { required: true, message: "请输入权重", trigger: "blur" },
                ],
            },
            // 
            dialogSelectedStoreListVisible:false,
            currentStoreList: [],


        };
    },
    created() {
    },
    filters: {
        getDateToStr: function (value) {
            if (value != null) {
                var typeIdArr = value.split(',')
                let name = "";
                if (typeIdArr.length == 1) {
                    name = typeIdArr[0] + '天'
                } else if (typeIdArr.length == 2) {
                    name = typeIdArr[0] + '天' + typeIdArr[1] + '时'
                } else if (typeIdArr.length == 3) {
                    name = typeIdArr[0] + '天' + typeIdArr[1] + '时' + typeIdArr[2] + '分'
                }
                return name;
            } else {
                return '--'
            }
        },
    },
    methods: {
        hoverContent(row) {
            this.rowInfo = row;
            this.dialogSeeContent = true;
        },
        //详情
        rowContentHandle(row, type) {
            switch (type) {
                case 1:
                this.rowInfo.title = '使用说明'
                    this.rowInfo.content = row.applicableDescription
                    this.dialogSeeContent = true
                    break;
                case 2:
                    this.currentStoreList = row.storeInfoDOS;
                    this.dialogSelectedStoreListVisible = true;
                    break;
                default:
                    break;
            }
        },

        selectShopTypeChange(val) {
            this.editForm.couponShopInfoDOList = [];
        },
        // 点击添加
        onAdd() {
            this.addOrEditDialog = true
        },

        // 点击添加---取消
        clickAddOrEditCancel() {
            this.resetAddOrEditData()
        },
        // 点击添加---确定
        clickAddOrEditConfirm() {
            this.$refs.editForm.validate(async (valid) => {
                if (valid) {
                    this.addOrEditBtnLoading = true

                    if (this.editForm.selectShopType == 2 && this.editForm.couponShopInfoDOList.length == 0) {
                        this.$message({
                            message: "请选择门店",
                            type: 'error'
                        });
                        return;
                    }
                    var shopIdArr = [];
                    if (this.editForm.couponShopInfoDOList.length) {
                        this.editForm.couponShopInfoDOList.forEach(shop => {
                            shopIdArr.push(shop.storeId)
                        });
                    }
                    this.editForm.applicableStoreIds = shopIdArr.join(',')
                    var sendFrom = {
                        experienceCouponId: this.editForm.experienceCouponId,
                        couponName: this.editForm.couponName,
                        validStartTime: this.editForm.validStartTime,
                        validEndTime: this.editForm.validEndTime,
                        originalPrice: this.editForm.originalPrice,
                        applicableStoreIds: this.editForm.applicableStoreIds,
                        applicableDescription: this.editForm.applicableDescription,
                    }
                    if (this.editForm.experienceCouponId > 0) {
                        await couponModule.updateCoupon(sendFrom).then((response) => {
                            this.addOrEditBtnLoading = false;
                            switch (response.data.code) {
                                case 200:
                                    this.$message({ message: response.data.message, type: 'success', })
                                    this.$emit('success')
                                    this.refresCurrentPagehData()
                                    this.resetAddOrEditData()
                                    break;
                                default:
                                    this.$message({
                                        message: response.data.message,
                                        type: 'error'
                                    });
                                    // this.$message.error(response.data.message);
                                    break;
                            }
                        }).catch((error) => {
                            //
                            this.addOrEditBtnLoading = false;
                        });
                    } else {
                        await couponModule.saveCoupon(sendFrom).then((response) => {
                            this.addOrEditBtnLoading = false;
                            switch (response.data.code) {
                                case 200:
                                    this.$message({ message: response.data.message, type: 'success', })
                                    this.$emit('success')
                                    this.refreshData()
                                    this.resetAddOrEditData()
                                    break;
                                default:
                                    this.$message({
                                        message: response.data.message,
                                        type: 'error'
                                    });
                                    // this.$message.error(response.data.message);
                                    break;
                            }
                        }).catch((error) => {
                            //
                            this.addOrEditBtnLoading = false;
                        });
                    }
                }
            })
        },

        // 清除添加数据
        resetAddOrEditData() {
            this.addOrEditDialog = false
            this.editForm = {
                experienceCouponId: 0,
                couponName: "",
                validStartTime: "",
                validEndTime: "",
                originalPrice: "",
                applicableStoreIds: "",
                applicableDescription: "",
                selectShopType: 1,
                couponShopInfoDOList: [],
            }
        },
        // 点击编辑信息
        async clickEditInfo(row) {
            console.log("row", row);
            this.editForm = {
                experienceCouponId: row.experienceCouponId,
                couponName: row.couponName,
                validStartTime: row.validStartTime,
                validEndTime: row.validEndTime,
                originalPrice: row.originalPrice,
                applicableStoreIds: row.applicableStoreIds,
                applicableDescription: row.applicableDescription,
                selectShopType: row.applicableStoreIds.length ? 2 : 1,
                couponShopInfoDOList: row.applicableStoreIds.length ? row.storeInfoDOS : [],
            }
            this.addOrEditDialog = true
            // if (this.permission.getCouponInfo) {
            //     this.getDetailById(row.experienceCouponId)
            // } else {
            //     this.$message.error("您没有查看权限")
            // }
        },
        //
        // async getDetailById(experienceCouponId) {
        //     try {
        //         let data = await couponManager.getCouponInfo({ experienceCouponId: experienceCouponId });
        //         if (data.code == 200) {
        //             this.editForm = data.data;
        //             if (data.data.couponShopInfoDOList == null || data.data.couponShopInfoDOList == '') {
        //                 this.editForm.couponShopInfoDOList = [];
        //             }
        //             if (data.data.businessId == 0) {
        //                 this.editForm.businessId = '';
        //             }
        //             this.addOrEditDialog = true
        //         }
        //     } catch (error) {
        //         //
        //     }
        // },
        // 点击删除
        async deleteAdmin(row, index) {
            try {
                let { code, message } = await couponManager.removeCoupon({ experienceCouponId: row.experienceCouponId });
                if (code === 200) {
                    // this.$message.success(message);
                    this.$message({
                        message: message,
                        type: 'success'
                    });
                    // this.$message({ message: "删除成功", type: "success" });
                    this.delRefresh(index);
                }
            } catch (error) {
                console.log(error)
            }
        },

        
        //
        onchoiceShops() {
            this.handlePermission();
            if (this.permission.couponSelectStoreList) {
                this.dialogShopListVisible = true
            } else {
                this.$message.error('您没有选择权限')
            }
        },
        handleChangeSelectShopArr(itemArr) {
            console.log("itemArr", itemArr);
            this.editForm.couponShopInfoDOList = itemArr;
            this.dialogShopListVisible = false
        },





    },
};
</script>

<style scoped>
.session-bg {
    display: flex;
    flex-wrap: wrap;
}

.session-label {
    margin: 3px 5px 3px 0;
    background-color: #ff8143;
    color: white;
    padding: 0 5px;
    border-radius: 5px;
    line-height: 25px;
}

.card-bg {
    padding: 15px;
    display: flex;
    flex-flow: wrap;
}

.card-bg-noData {
    padding: 30px;
    text-align: center;
}

.goods-card {
    border-radius: 4px;
    border: 1px solid #EBEEF5;
    background-color: #FFFFFF;
    overflow: hidden;
    color: #303133;
    transition: 0.3s;
    /* box-sizing: border-box; */


    width: 275px;
    /* height: 160px; */
    margin: 15px 0px 15px 15px;
    /* background: red; */
    display: flex;
    padding: 15px;
    align-items: center;
}


.goods-image {
    width: 90px;
    height: 120px;
    overflow: hidden;
    display: flex;
    align-items: center;
    /* margin: 20px 0px 20px 15px; */
}

.goods-detail {
    /* padding: 20px 15px 20px 15px; */
    margin-left: 15px;
}

.goods-name {
    margin-bottom: 5px;
}

.goods-other {
    margin-bottom: 5px;
}


.text-line-1 {
    display: -webkit-box;
    word-break: break-all;
    /*超出文字用省略号代替*/
    text-overflow: ellipsis;
    /*竖直方向的超出和隐藏*/
    -webkit-box-orient: vertical;
    /*设定行数为2*/
    -webkit-line-clamp: 1;
    /*多出部分隐藏*/
    overflow: hidden;
}

.text-line-2 {
    display: -webkit-box;
    word-break: break-all;
    /*超出文字用省略号代替*/
    text-overflow: ellipsis;
    /*竖直方向的超出和隐藏*/
    -webkit-box-orient: vertical;
    /*设定行数为2*/
    -webkit-line-clamp: 2;
    /*多出部分隐藏*/
    overflow: hidden;
}

/* ---------------------------------------------------- */
/* .editor-custom code {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  background-color: #f1f1f1;
  border-radius: 3px;
  padding: 3px 5px;
  margin: 0 3px;
}
.editor-custom pre code {
  display: block;
}


.editor-custom ul li {
  list-style-type: disc !important;
}
.editor-custom ol li {
  list-style-type: decimal !important;
}

.html-content{
white-space: pre-wrap;
}
.html-content>>>img{
  max-width: 100%;
} */
</style>

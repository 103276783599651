var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-form",
            { staticClass: "fr-s-s warp", attrs: { inline: true } },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      placeholder: "请输入体验券ID",
                      clearable: "",
                    },
                    model: {
                      value: _vm.query.experienceCouponId,
                      callback: function ($$v) {
                        _vm.$set(_vm.query, "experienceCouponId", $$v)
                      },
                      expression: "query.experienceCouponId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "" },
                      on: { click: _vm.refreshData },
                    },
                    [_vm._v("搜索")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _vm.permission.saveCoupon
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "" },
                          on: { click: _vm.onAdd },
                        },
                        [_vm._v("添加")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.showLoading,
                  expression: "showLoading",
                },
              ],
              ref: "table",
              attrs: {
                data: _vm.tableData,
                border: "",
                stripe: "",
                "row-key": "experienceCouponId",
                height: _vm.tableHeight,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "experienceCouponId",
                  label: "编号",
                  align: "center",
                  width: "60",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.$index + 1))]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "experienceCouponId",
                  label: "体验券ID",
                  align: "center",
                  "min-width": "100",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "couponName",
                  label: "体验券名称",
                  align: "center",
                  "min-width": "140",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "validStartTime",
                  label: "有效期开始时间",
                  align: "center",
                  "min-width": "140",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("formatDate")(scope.row.validStartTime)
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "validEndTime",
                  label: "有效期结束时间",
                  align: "center",
                  "min-width": "140",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("formatDate")(scope.row.validEndTime)
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "originalPrice",
                  label: "体验券金额",
                  align: "center",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.originalPrice
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  parseFloat(scope.row.originalPrice).toFixed(
                                    2
                                  ) + "元"
                                )
                              ),
                            ])
                          : _c("span", [_vm._v("-")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "applicableDescription",
                  label: "使用说明",
                  "min-width": "100",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.rowContentHandle(scope.row, 1)
                              },
                            },
                          },
                          [
                            _c("el-link", { attrs: { type: "primary" } }, [
                              _vm._v("详情"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "nickName",
                  label: "用户昵称",
                  align: "center",
                  "min-width": "100",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "verificationTime",
                  label: "核销时间",
                  align: "center",
                  "min-width": "140",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("formatDate")(scope.row.verificationTime)
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "verifierName",
                  label: "核销人名称",
                  align: "center",
                  "min-width": "100",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "verificationStoreName",
                  label: "核销门店",
                  align: "center",
                  "min-width": "120",
                },
              }),
            ],
            1
          ),
          _c("el-pagination", {
            ref: "pagination",
            attrs: {
              "current-page": _vm.query.offset,
              "page-sizes": [10, 30, 50, 100],
              "page-size": _vm.query.limit,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pageTotal,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("show-content", {
        attrs: { title: _vm.rowInfo.title, content: _vm.rowInfo.content },
        model: {
          value: _vm.dialogSeeContent,
          callback: function ($$v) {
            _vm.dialogSeeContent = $$v
          },
          expression: "dialogSeeContent",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }